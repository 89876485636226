import { Controller } from "@hotwired/stimulus"
import { FetchRequest } from "@rails/request.js"

function withProgress(request) {
  $('button[type=submit]').attr('disabled', 'disabled');
  $('button[type=submit]').addClass('disabled');

  return request.then((response) => {
    $('button[type=submit]').removeAttr('disabled');
    $('button[type=submit]').removeClass('disabled');
    return response
  })
}

// Connects to data-controller="state-select"
export default class extends Controller {
  static values = {
    url: String,
    param: String
  }

  handleChange = async () => {
    const request = new FetchRequest("get", this.urlValue, {
      responseKind: "turbo-stream",
      query: {
        country: $('select#booking_country :selected').val()
      }
    })
    return await withProgress(request.perform())
  }
}
