import NestedForm from 'stimulus-rails-nested-form'
import { FetchRequest, get } from "@rails/request.js"

function withProgress(request) {
  $('button[type=submit]').attr('disabled', 'disabled');
  $('button[type=submit]').addClass('disabled');
  // showProgressBar()

  return request.then((response) => {
    // hideProgressBar()
    $('button[type=submit]').removeAttr('disabled');
    $('button[type=submit]').removeClass('disabled');
    return response
  })
}


export default class extends NestedForm {
  static targets = ["select", "template", "target"]
  static values = {
    url: String,
    param: String
  }
  
  connect () {
    this.form = document.getElementById("booking_form")
    this.bookingSpots = document.getElementById("booking_num_spots")
    this.maxSpotIndex = this.bookingSpots.options.length - 1
    this.spotIndex = this.bookingSpots.selectedIndex
  }
  handleChange = async (event) => {
    this.syncSpots()
    let response = this.makeRequest() 
    // if (response.ok) { console.log("yay") }
  }
  add(event) {
    if (this.spotIndex < this.maxSpotIndex) { 
      super.add(event)
      this.spotIndex += 1 
      this.bookingSpots.selectedIndex = this.spotIndex
      this.makeRequest()
    } else { 
      // TODO: show floating notification banner with message "no more spots" or something
      let btn = document.getElementById("add_button");
      btn.classList.add('shake-animation');
      setTimeout(function(){
        btn.classList.remove('shake-animation');
      }, 500);
    }
    
  }
  remove(event) {
    super.remove(event)
    if (this.spotIndex > 0) { this.spotIndex -= 1 }
    this.bookingSpots.selectedIndex = this.spotIndex
    this.makeRequest()
  }

  makeRequest = async () => {
    const request = new FetchRequest("post", this.urlValue, {
      responseKind: "turbo-stream",
      body: new FormData(this.form)
    })
    return await withProgress(request.perform())
  }
  
  syncSpots() {
    let nestedForms = document.getElementsByClassName("nested-form-wrapper")
    if (this.spotIndex > this.bookingSpots.selectedIndex) {
      for(let i=this.spotIndex; i>this.bookingSpots.selectedIndex; i--) {
        // remove
        // console.log("remove loop " + i);
        let wrapper = nestedForms[nestedForms.length -1] 
        if (wrapper.dataset.newRecord === 'true') {
          wrapper.remove()
        } else {
          wrapper.style.display = 'none'
          const input = wrapper.querySelector("input[name*='_destroy']")
          input.value = '1'
        }
      }
    } else if (this.spotIndex < this.bookingSpots.selectedIndex) { 
      // console.log("add");
      for(let i=this.spotIndex; i<this.bookingSpots.selectedIndex; i++) {
        // console.log("add loop " + i);
        // ADD 
        const content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime().toString())
        this.targetTarget.insertAdjacentHTML('beforebegin', content)
      }
    }
    this.spotIndex = this.bookingSpots.selectedIndex
  }
}

