import { Controller } from "@hotwired/stimulus"

/*
* Javascript to show and hide cookie banner using localstroage
*/

export default class extends Controller {
  connect () {
    // Checks the localstorage and shows Cookie banner based on it
    let isCookieAccepted = localStorage.getItem("iag_isBannerHidden");
    if(isCookieAccepted === null) {
        localStorage.clear();
        localStorage.setItem("iag_isBannerHidden", "no");
        this.showNotificationBanner();
    }
    if(isCookieAccepted === "no"){
      this.showNotificationBanner();
    }
  }
  
  // Hides the Cookie banner and saves the value to localstorage
  hideNotificationBanner = (event) => {
    localStorage.setItem("iag_isBannerHidden", "yes");
    let notificationBanner = document.getElementById("hellobar-bar");
    // let notificationBanner = document.getElementsByClassName("nk-cookie-banner")[0];
    $('#hellobar-bar').fadeOut();
    notificationBanner.style.display = "none";
  }
  
  showNotificationBanner = (event) => {
    let notificationBanner = document.getElementById("hellobar-bar");
    notificationBanner.style.display = "";
  }
}
