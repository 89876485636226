import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="navigation"
export default class extends Controller {
  static menu

  initialize() {
    const navExpand = [].slice.call(document.querySelectorAll(".nav-expand"));
    const backLink = `<li class="nav-item">
        <a class="nav-link nav-back-link" href="javascript:;">
            Back
        </a>
    </li>`;
  
    navExpand.forEach((item) => {
      item
        .querySelector(".nav-link")
        .addEventListener("click", (e) => {e.preventDefault(); item.classList.add("active");});
      item
        .querySelector(".nav-back-link")
        .addEventListener("click", (e) => item.classList.remove("active"));
    });
  
    this.menu = document.getElementById("menuToggle");
    this.menu.addEventListener("click", this.toggleNav);
  }

  toggleNav() {
    document.body.classList.toggle("nav-is-toggled");
  }
}
