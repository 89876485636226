import { Controller } from '@hotwired/stimulus'
import Swiper, { Pagination, Autoplay } from 'swiper'
// import 'swiper/swiper-bundle.min.css'
// import 'swiper/swiper.min.css'

export default class extends Controller {
  static swiper
  static isCustom
  static values = {
    options: Object
  }

  connect() {
    if (this.element.id == "carousel") {
      this.isCustom = true
    }
    this.swiper = new Swiper(this.element, {
      ...this.defaultOptions,
      ...this.optionsValue
    })
  }

  disconnect() {
    this.swiper.destroy()
    this.swiper = undefined
  }

  get defaultOptions() {
    return {
      loop: false,
      modules: [ Pagination, Autoplay ],
      autoplay: {
        delay: 6500,
        disableOnInteraction: false,
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
        renderBullet: function (index, className) {
          let title = document.getElementsByClassName("swiper-slide")[index].getElementsByTagName("h2");
          if (title.length > 0) { 
            return `<span class="${className}"><span class="swiper-desktop">${title[0].textContent}</span><span class="swiper-mobile">${index + 1}</span></span>`;
          } else {
            return `<span class="${className}">${index + 1}</span>`;
          }
        },
      },
    }
  }
}