import { Application } from "@hotwired/stimulus";

const application = Application.start();

// Configure Stimulus development experience
application.debug = true;
window.Stimulus = application;

document.addEventListener("turbolinks:before-cache", () => {
  application.controllers.forEach((controller) => {
    if (typeof controller.teardown === "function") {
      controller.teardown();
    }
  });
});

Turbo.setProgressBarDelay(200)

export { application };
